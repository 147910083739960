import { PARAM } from "../../constants/constants";
import FreeSearchTextRules from "./customInputs/freeSearchTextRules/FreeSearchTextRules";
import MultipleSelectRules from "./customInputs/multipleSelectRules/MultipleSelectRules";

const customFilters = [
  {
    label: "Képen szereplő személy",
    param: PARAM.CONTRIBUTORS,
    placeholder: "Személy neve...",
    buttonText: "Személy hozzáadása",
    filterInput: FreeSearchTextRules,
    isApplied: false,
  },
  {
    label: "Fotós(ok)",
    param: PARAM.CREATORS,
    placeholder: "Fotós neve...",
    buttonText: "Fotós hozzáadása",
    filterInput: FreeSearchTextRules,
    isApplied: false,
  },
  {
    label: "Kulcsszavak",
    param: PARAM.KEYWORDS,
    placeholder: "Kulcsszó...",
    buttonText: "Kulcsszó hozzáadása",
    filterInput: MultipleSelectRules,
    isApplied: false,
  },
];

class CustomFilterManager {
  constructor() {
    this.customFilters = customFilters;
    this.labelSwiper = null;
    this.keywordsFilterRules = null;
    this.#attachAddFilterGroupListener();
    this.#loadCustomFiltersFromParams();
  }

  reset() {
    const searchParams = new URLSearchParams(window.location.search);
    this.customFilters.forEach((filter) => {
      filter.isApplied = false;

      $(`#${filter.param}`).remove();
      searchParams.delete(filter.param);

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    });
  }

  injectLabelSwiper(labelSwiper) {
    this.labelSwiper = labelSwiper;
    setTimeout(() => {
      this.keywordsFilterRules?.update(labelSwiper);
    }, 2000);
  }

  // add filter button listener
  #attachAddFilterGroupListener = () => {
    $("#add-filter-button").on("click", () => {
      this.#appendCustomFilterSelect();
      $("#add-filter-button").attr("disabled", true);
    });
  };

  // append filter select component
  #appendCustomFilterSelect() {
    $(".pbf-filter-group-container").append(
      `<div id="filter-rule-select-container"></div>`
    );

    const filterRuleSelectContainer = $("#filter-rule-select-container");

    // add label to filter group select
    filterRuleSelectContainer.append(
      `<h1 class="card-label">Szűrőfeltétel hozzáadása</h1>`
    );

    // add filter rule select
    filterRuleSelectContainer.append(
      '<select id="filter-rule-select"></select>'
    );

    // add default value to select
    $("#filter-rule-select").append(
      '<option value="" disabled selected>Válasszon szűrőfeltételt</option>'
    );

    this.customFilters.forEach((filter) => {
      const optionText = filter.label;
      const param = filter.param;
      const disabled = filter.isApplied;

      $("#filter-rule-select").append(
        `<option value="${param}" ${
          disabled ? "disabled" : ""
        }>${optionText}</option>`
      );
    });

    this.#attachSelectCustomFilterListener();
  }

  // select filter listener
  #attachSelectCustomFilterListener() {
    const self = this;
    $("#filter-rule-select").selectmenu({
      width: "100%",
      change: function (event, ui) {
        const param = ui.item.value;

        // remove select component from dom
        $("#filter-rule-select-container").remove();

        // find the corresponding filter object, set isApplied to true
        const customFilter = customFilters.find(
          (filter) => filter.param === param
        );
        customFilter.isApplied = true;

        // enable add filter button if there is still a filter to add
        const isThereFilterToApply = customFilters.some(
          (filter) => !filter.isApplied
        );

        if (isThereFilterToApply) {
          $("#add-filter-button").attr("disabled", false);
        }

        const customFilterInput = new customFilter.filterInput(
          param,
          customFilter
        );

        if (param === PARAM.KEYWORDS) {
          customFilterInput.update(self.labelSwiper);
        }
      },
    });
  }

  // init custom filters on first load
  #loadCustomFiltersFromParams() {
    this.customFilters.forEach((filter) => {
      const searchParams = new URLSearchParams(window.location.search);
      const hasExistingParam = searchParams.get(filter.param) !== null;

      //debugger;

      if (hasExistingParam) {
        filter.isApplied = true;
        const customFilterInput = new filter.filterInput(
          filter.param,
          filter,
          hasExistingParam
        );

        if (filter.param === PARAM.KEYWORDS) {
          this.keywordsFilterRules = customFilterInput;
        }
      }
    });
  }

  // update keywords filter
  updateKeywordsFilter() {
    console.log("keyword filter rules: ", this.keywordsFilterRules);

    if (!this.keywordsFilterRules) {
      this.keywordsFilterRules = new MultipleSelectRules(
        PARAM.KEYWORDS,
        customFilters[2]
      );
    }
    console.log("update fn: ", this.keywordsFilterRules?.update);
    
    this.keywordsFilterRules?.update(this.labelSwiper);
  }
}

export default CustomFilterManager;
