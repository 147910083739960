import App from '../app';
import search from "../search/search";
import PhotobankHeroSearch from "./photobankHeroSearch/PhotobankHeroSearch";
import PhotobankFilter from "./photoFilter/PhotobankFilter";
import PhotoItem from "./photoItem/PhotoItem";
import PhotoMain from "./photoMain/PhotoMain";

import "../../css/photobank.css";

const Modules = {
  search,
  PhotoItem,
  PhotoMain,
  PhotobankFilter,
  PhotobankHeroSearch,
};

App.init(Modules);
