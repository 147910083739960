import ModelComponent from '../../ModelComponent';
import { parseQuery } from "../../helpers/helpers";
export const eventTarget = new EventTarget();

class PhotoMainModel extends ModelComponent {
  constructor() {
    super();
    this.state = {
      page: 0,
      isFetchingFresh: false,
      isFirstFetch: true,
    };

    this.loading = false;

    this.fetchAvailableImagesCount();
    this.attachEventListener();
  }

  //fetch available images count
  fetchAvailableImagesCount() {
    $.ajax({
      url: SITE_URL + "api/photobank/v2/search",
      method: "GET",
      success: (response) => {
        const numFound = response?.data?.response?.numFound || 0;
        const formattedNumFound = new Intl.NumberFormat().format(numFound);
        $("#pbf-hero-search").attr(
          "placeholder",
          `Keresés ${formattedNumFound} fotó között...`
        );
      },
      error: (error) => {
        console.log("error: ", error);
      },
    });
  }

  // Attach an event which is listening for event calling getPage
  attachEventListener() {
    eventTarget.addEventListener("fetch-data", () => {
      this.query = parseQuery(window.location.href);
      this.state.page = 0;

      $("#pbf-result-count").text("");

      this.state.isFetchingFresh = true;
      $(".photo-list").css("display", "none");
      $("#photo-loader").removeClass("d-none");

      this.getPage();
    });
  }

  getPage() {
    if (this.loading) return;
    this.loading = true;

    let query = JSON.parse(JSON.stringify(this.query));

    if (Object.keys(query).length) {
      if (query && query.type === "r") {
        query.type = "recent";
      } else if (query && query.type === "c") {
        query.type = "canvas";
      } else if (
        query &&
        (query.query ||
          query.min ||
          query.max ||
          query.category ||
          query.filters)
      ) {
        query.type = "search";
      }
    } else {
      query.type = "recommended";
    }

    $.ajax({
      url: SITE_URL + "api/photobank/v2/search",
      method: "GET",
      data: {
        page: this.state.page,
        limit: 25,
        type: query.type,
        query: query,
      },
      success: (response) => {
        this.loading = false;
        // response = JSON.parse(response);
        response.page = this.state.page;
        this.emit("gotPage", response);
        if (response?.data?.response?.docs?.length) {
          this.setState({ page: this.state.page + 1 });
        }

        // dispatch event on eventTarget with response
        eventTarget.dispatchEvent(
          new CustomEvent("gotResponse", { detail: response })
        );

        $(".photo-list").css("display", "block");
        $("#photo-loader").addClass("d-none");
      },
      error: (error) => {
        this.emit("gotPage", error);
      },
    });
  }
}

export default PhotoMainModel;
