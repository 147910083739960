import "select2";
import CustomFilterContainer from "../../helpers/CustomFilterContainer";
require("select2/dist/css/select2.css");

class MultipleSelectRules {
  constructor(param, filterObj) {
    this.param = param;
    this.filterObj = filterObj;

    CustomFilterContainer.append(filterObj.label, param);
    this.parent = $(`#${param}`);

    this.#appendMultipleSelect();
    this.#attachSelectListener();
  }

  update(labelSwiper) {
    let selectElement = $(`#${this.param}-select`);

    console.log("selectElement", selectElement);

    if (selectElement.length === 0) {
      console.log("appending select");

      this.#appendMultipleSelect();
      this.#attachSelectListener();
      selectElement = $(`#${this.param}-select`);
    }

    const searchParams = new URLSearchParams(window.location.search);
    const selectedValues = searchParams.get(this.param) || "[]";
    const parsedValues = JSON.parse(selectedValues);

    selectElement.empty();

    labelSwiper.labels.forEach((label) => {
      const isSelected = parsedValues.includes(label.value);
      const newOption = new Option(label.value, label.value, false, isSelected);
      selectElement.append(newOption);
    });

    selectElement.trigger("change");
  }

  #appendMultipleSelect() {
    $(`#${this.param}-input-container`).append(
      `<select id="${this.param}-select" multiple="multiple"></select>`
    );

    $(document).ready(() => {
      $(`#${this.param}-select`).select2({
        placeholder: this.filterObj.placeholder,
        width: "100%",
      });
    });
  }

  #attachSelectListener() {
    const param = this.param;
    let isRecentlyAdded = true;
    const filterObj = this.filterObj;

    $(`#${this.param}-select`).on("change", function (e) {
      const selectedValues = $(this).val();

      const searchParams = new URLSearchParams(window.location.search);

      if (selectedValues.length !== 0) {
        searchParams.set(param, JSON.stringify(selectedValues));
        isRecentlyAdded = false;
      } else {
        // remove custom rule
        if (!isRecentlyAdded) {
          $(`#${param}`).remove();
          searchParams.delete(param);
          filterObj.isApplied = false;
        }
      }

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams}`
      );
    });
  }
}

export default MultipleSelectRules;
