import 'chart.js';
import { Chart } from 'chart.js';
import { DateTime } from "luxon";
import { eventTarget } from "../../photoMain/PhotoMainModel";

class DataChart {
  constructor(fromDateDatePicker, toDateDatePicker) {
    this.fromDateDatePicker = fromDateDatePicker;
    this.toDateDatePicker = toDateDatePicker;

    $(document).ready(() => {
      this.#initChart();
    });
  }

  #initChart() {
    const chartElement = document.getElementById("pbf-data-chart");
    if (!chartElement) {
      console.error("Chart element not found!");
      return;
    }

    const ctx = chartElement.getContext("2d");

    const self = this;

    this.chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "#26a69a",
            // borderColor: '#26a69a',
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          fontSize: 16,
          fontColor: "#6c6c6c",
          fontStyle: "bold",
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItems) {
              return `Év: ${tooltipItems[0].label}`;
            },
            label: function (tooltipItem) {
              return `${tooltipItem.value} fotó`;
            },
          },
        },
        scales: {
          yAxes: [
            {
              type: "logarithmic",
              scaleLabel: {
                display: true,
                labelString: "Fotók száma",
              },
              ticks: {
                min: 1,
                callback: function (value) {
                  return value.toLocaleString();
                },
                maxTicksLimit: 5,
              },
              gridLines: {
                color: "#efefef",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 10,
                maxRotation: 0,
                minRotation: 0,
                autoSkip: true,
                autoSkipPadding: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        responsive: true,
        onClick: function (...args) {
          const activePoints = this.getElementAtEvent(event);
          if (activePoints.length > 0) {
            const clickedElementIndex = activePoints[0]._index;
            const date = this.data.labels[clickedElementIndex];

            const startDate = DateTime.fromISO(date).startOf("year").toJSDate();
            const endDate = DateTime.fromISO(date).endOf("year").toJSDate();

            const searchParams = new URLSearchParams(window.location.search);

            searchParams.set(
              "min",
              DateTime.fromJSDate(startDate).toISO().split("T")[0]
            );
            searchParams.set(
              "max",
              DateTime.fromJSDate(endDate).toISO().split("T")[0]
            );

            window.history.replaceState(
              {},
              "",
              `${window.location.pathname}?${searchParams.toString()}`
            );

            self.fromDateDatePicker.setDateOnClickChartBar(startDate);
            self.toDateDatePicker.setDateOnClickChartBar(endDate);

            eventTarget.dispatchEvent(new CustomEvent("fetch-data"));
          }
        },
      },
    });
  }

  update(labels, allLabels, activeData, allData, numberOfDocuments) {
    this.chart.data.labels = allLabels;
    this.chart.data.datasets.forEach((dataset) => {
      dataset.data = allData;
      dataset.backgroundColor = allLabels.map((label) => {
        return labels.includes(label) ? "#26a69a" : "#e0e0e0";
      });
    });
    const formattedNumberOfDocuments = numberOfDocuments.toLocaleString();
    this.chart.options.title.text = `${formattedNumberOfDocuments} fotó időbeli eloszlása`;
    this.chart.update();
  }
}

export default DataChart;
