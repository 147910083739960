class Tooltip {
  constructor(tooltipParentId, tooltipContent) {
    this.parentId = tooltipParentId;
    this.content = tooltipContent;
    this.#toggleTooltipListener();
  }

  #toggleTooltipListener() {
    const self = this;
    $(document).ready(function () {
      $(`${self.parentId}`).tooltip({
        disabled: false,
        position: { my: "center top+10", at: "center bottom" },
        content: self.content,
      });

      $(`${self.parentId}`).tooltip("enable");
    });
   }
}

export default Tooltip;
