function old10Listener(e, param){
  // set params on change
  const value = e.target.checked;
  const searchParams = new URLSearchParams(window.location.search);

  if(value){
    searchParams.set(param, value);
  }else {
    searchParams.delete(param);
  }

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${searchParams.toString()}`
  );
}

export default old10Listener;
