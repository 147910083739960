class TextInput {
    constructor(inputId,param) {
        this.input = $(`#${inputId}`);
        this.param = param;
        this.#initValueFromParam();
        this.#appendListener();
    }

    reset(){
        this.input.val("");

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(this.param);
        window.history.replaceState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
    }

    #initValueFromParam(){
        const searchParams = new URLSearchParams(window.location.search);
        const value = searchParams.get(this.param);
        if(value) {
            this.input.val(value);
        }
    }

    #appendListener(){
      const param = this.param;

        this.input.on("input", (e)=> {
          const searchParams = new URLSearchParams(window.location.search);
            if(e.target.value === "") {
                searchParams.delete(param);
            } else {
                searchParams.set(param, e.target.value);
            }

            window.history.replaceState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
        });
    }
}

export default TextInput;
