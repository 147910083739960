import { DateTime } from "luxon";
import { PARAM } from "../../../constants/constants";

class ToDateDatePicker {
  constructor() {
    this.datePicker = $("#datepicker-end");
    this.datePickerPair = $("#datepicker-start");
    this.slider = $("#slider-range");
    this.#initDatepicker();
    this.#attachListener();
  }

  reset() {
    const [_, maxYear] = this.slider
      .attr("data-defaultrange")
      .slice(1, -1)
      .split(",");
    const maxDate = DateTime.fromISO(`${maxYear}-12-31`).toJSDate();

    // destroy existing datepicker to avoid datepicker having funny behavior
    this.datePicker.datepicker("destroy");

    // reinitialize datepicker
    this.datePicker.datepicker({ dateFormat: "yy-mm-dd" });
    this.datePicker.datepicker("setDate", maxDate);

    this.#setQueryParam(maxDate);
  }

  setDateOnClickChartBar(date) {
    this.datePicker.datepicker("setDate", date);
  }

  #initDatepicker() {
    this.datePicker.datepicker({ dateFormat: "yy-mm-dd" });

    const searchParams = new URLSearchParams(window.location.search);
    let currentMax = searchParams.get(PARAM.DATE_TO);

    if (!currentMax) {
      const [_, maxYear] = this.slider
        .attr("data-defaultrange")
        .slice(1, -1)
        .split(",");
      currentMax = DateTime.fromISO(`${maxYear}-12-31`).toJSDate();
    } else {
      currentMax = DateTime.fromISO(currentMax).toJSDate();
    }

    this.datePicker.datepicker("setDate", currentMax);
  }

  #setQueryParam(date) {
    const searchParams = new URLSearchParams(window.location.search);
    const dateString = DateTime.fromJSDate(date).toISO().split("T")[0];
    searchParams.set(PARAM.DATE_TO, dateString);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  update(min, max) {
    const minDate = DateTime.fromISO(`${min}-01-01`).toJSDate();
    const maxDate = DateTime.fromISO(`${max}-12-31`).toJSDate();

    // set min and max date to avoid selecting invalid dates
    this.#updateMinMaxDate(minDate, maxDate);
  }

  #updateMinMaxDate(min, max) {
    const datepickerPairValue = this.datePickerPair.datepicker("getDate");
    const actualMin =
      datepickerPairValue !== null && datepickerPairValue > min
        ? datepickerPairValue
        : min;

    this.datePicker.datepicker("option", "minDate", actualMin);
    this.datePicker.datepicker("option", "maxDate", max);
  }

  #attachListener() {
    const self = this;
    this.datePicker.on("change", (event) => {
      const value = event.target.value;

      // update the min date of the end datepicker to sync with the start datepicker
      const datePickerPairMaxDate = DateTime.fromISO(value).toJSDate();
      self.datePickerPair.datepicker(
        "option",
        "maxDate",
        datePickerPairMaxDate
      );

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(PARAM.DATE_TO, value);

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    });
  }
}

export default ToDateDatePicker;
