import { eventTarget } from "../photoMain/PhotoMainModel";

class FetchResponseListener {
  constructor(fromDateDatePicker, toDateDatePicker, labelSwiper, filterButton, dataChart) {
    this.labelSwiper = labelSwiper;
    this.filterButton = filterButton;
    this.fromDateDatePicker = fromDateDatePicker;
    this.toDateDatePicker = toDateDatePicker;
    this.dataChart = dataChart;
    this.#attachEventListener();
  }

  #attachEventListener() {
    eventTarget.addEventListener("gotResponse", (response) => {
      if (response.detail.page !== 0) return;
      // updating ui on fetching fresh data
      // do not update if fetching data for infinite scroll
      this.#updateLabels(response);
      this.#updateResultsCount(response);
      this.#updateChart(response);
      this.filterButton.updateCounter();
    });
  }

  #updateChart(response) {
    $.ajax({
      url: SITE_URL + "/photobank/map",
      method: "GET",
      data: {},
      success: (wholeMapDataResponse) => {
        // fetch to get all data for the chart, not just the active data
        const parsedResponse = JSON.parse(wholeMapDataResponse);
        const mapDataRes = parsedResponse?.data?.facet_ranges?.date || {};

        const allLabels = Object.keys(mapDataRes);
        const allData = Object.values(mapDataRes);

        // original fetch
        const resData = response?.detail?.data?.facet_ranges?.date || {};
        const labels = Object.keys(resData);
        const activeData = Object.values(resData);
        const numberOfDocuments =
          response?.detail?.data?.response?.numFound || 0;

        if (numberOfDocuments === 0) {
          $(".pbf-chart-container").addClass("hidden");
        } else {
          $(".pbf-chart-container").removeClass("hidden");
        }

        this.dataChart.update(
          labels,
          allLabels,
          activeData,
          allData,
          numberOfDocuments
        );
      },
      error: (error) => {
        console.log("error: ", error);
      },
    });
  };

  #updateLabels(response) {
    const labels = response?.detail?.data?.facet_counts?.categories || {};

    this.labelSwiper.updateLabels(labels);
  }

  #updateResultsCount(response) {
    const count = response?.detail?.data?.response?.numFound || 0;
    $("#pbf-result-count").text(`${count} találat megjelenítve`);
  }
}

export default FetchResponseListener;
