import CustomFilterContainer from "../../helpers/CustomFilterContainer";

class FreeSearchTextRules {
  constructor(param, filterObj, hasExistingParam) {
    this.param = param;
    this.maxRuleCount = 5;
    this.ruleCount = 0;
    this.filterObj = filterObj;
    this.paramValues = [];

    CustomFilterContainer.append(filterObj.label, param);
    this.parent = $(`#${param}`);

    this.#appendAddFilterGroupRuleButton();
    this.#addRuleButtonListener();

    if(hasExistingParam){
      this.#initFilterGroupRules();
    }else{
      this.#appendCloseableInput();
    }
  }

  #appendAddFilterGroupRuleButton = () => {
    $(this.parent).append(
      `<button
         id="${this.param}-button"
         ${this.maxRuleCount === this.ruleCount ? 'disabled="true"' : ""}
         class="text-button">
        <span class="icon"><i class=\"fas fa-plus\"></i></span>
        ${this.filterObj.buttonText}
    </button>`
    );
  };

  #addRuleButtonListener = () => {
    $(`#${this.param}-button`).on("click", () => {
      this.#appendCloseableInput();
      this.#onFilterGroupRuleInputChange();

      if(this.ruleCount === this.maxRuleCount){
        $(`#${this.param}-button`).attr("disabled", true);
      }
    });
  };

  #appendCloseableInput = () => {
    this.ruleCount+=1;
    this.paramValues.push({id: this.ruleCount, value: ""});

    $(`#${this.param}-input-container`).append(
      `<div class="closable-input-container">
        <input id="${this.param}-${this.ruleCount}" placeholder="${this.filterObj.placeholder}" class="text-input">
          <button id="${this.param}-${this.ruleCount}-close" class="input-button">
            <span>
              <i class="fas fa-times close-icon"></i>
            </span>
          </button>
        </input>
      </div>`
    );

    this.removeFilterRuleListener(this.ruleCount);
    this.#onFilterGroupRuleInputChange(this.ruleCount);
  };

  removeFilterRuleListener(ruleCount){
    $(`#${this.param}-${ruleCount}-close`).on("click", () => {
      $(`#${this.param}-${ruleCount}-close`).parent().remove();
      $(`#${this.param}-button`).attr("disabled", false);

      this.removeParam(ruleCount);
      this.setParams();

      this.ruleCount-=1;

      // check if there is no input left, remove filter group
      if(this.ruleCount === 0){
        $(`#${this.param}`).remove();
        // enable add filter button
        this.filterObj.isApplied = false;
        $("#add-filter-button").attr("disabled", false);
      }
    });
  };

  #onFilterGroupRuleInputChange(ruleCount) {
    $(`#${this.param}-${ruleCount}`).on("input", (e) => {
      const value = e.target.value;

      const currentParam = this.paramValues.find((param) => param.id === ruleCount);
      currentParam.value = value;

     this.setParams();
    });
  };

  setParams(){
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(this.param);

    //avoid setting empty params
    const hasParams = this.paramValues.some((param) => param.value !== "");

    if(hasParams){
      const currentParams = this.paramValues.map((param) => param.value);
      const stringifyParams = JSON.stringify(currentParams);

      searchParams.set(this.param, stringifyParams);
    }

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams}`
    );
  }

  removeParam(id){
    this.paramValues = this.paramValues.filter((param) => param.id !== id);
    this.setParams();
  }

  // init filter group rules from params on first load
  #initFilterGroupRules(){
    const searchParams = new URLSearchParams(window.location.search);
    const existingParam = searchParams.get(this.param);
    const parsedParams = JSON.parse(existingParam);

    parsedParams.forEach((value) => {
      this.ruleCount++;
      this.paramValues.push({id: this.ruleCount, value: value});

      $(`#${this.param}-input-container`).append(
        `<div class="closable-input-container">
            <input id="${this.param}-${this.ruleCount}" value="${value}" placeholder="${this.filterObj.placeholder}" class="text-input">
              <div id="${this.param}-${this.ruleCount}-close" class="input-button">
                <span>
                  <i class="fas fa-times close-icon"></i>
                </span>
              </div>
            </input>
          </div>`
      );

      this.removeFilterRuleListener(this.ruleCount);
      this.#onFilterGroupRuleInputChange(this.ruleCount);
    });
  }
}

export default FreeSearchTextRules;
