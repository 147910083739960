class Select {
  constructor(selectId, param) {
    this.select = $(`#${selectId}`);
    this.param = param;
    this.#appendListener();
    this.#initValueFromParam();
  }

  reset() {
    this.select.val("");
    this.select.selectmenu("refresh");

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(this.param);

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  #appendListener() {
    const param = this.param;

    this.select.selectmenu({
      width: "100%",
      change: function (event, ui) {
        const searchParams = new URLSearchParams(window.location.search);

        if(ui.item.value === ""){
          searchParams.delete(param);
        } else {
          searchParams.set(param, ui.item.value);
        }

        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${searchParams.toString()}`
        );
      }
    })
  }

  #initValueFromParam(){
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(this.param);
    
    if(value === null) return;
    this.select.val(value);
    this.select.selectmenu("refresh");
  }
}

export default Select;
