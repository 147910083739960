import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { PARAM } from "../../constants/constants";
import { eventTarget } from "../../photoMain/PhotoMainModel";

class LabelSwiper {
  constructor(customFilterManager) {
    this.swiper = null;
    this.customFilterManager = customFilterManager;
    this.labels = [];
    this.#initLabelSwiper();
    this.swiperWrapper = $(".swiper-wrapper");
    this.#attachLabelClickListener();
    this.#attachNavigationListener();
  }

  #initLabelSwiper() {
    this.swiper = new Swiper(".mySwiper", {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 10,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      freeMode: true,
    });
  }

  #attachNavigationListener() {
    $(".button-next").on("click", () => {
      this.swiper.slideNext();
    });

    $(".button-prev").on("click", () => {
      this.swiper.slidePrev();
    });

    // Add event listener to update button states
    this.swiper.on("slideChange", () => {
      const { isBeginning, isEnd } = this.swiper;

      // Disable/enable buttons based on swiper state
      $(".button-prev").attr("disabled", isBeginning);
      $(".button-next").attr("disabled", isEnd);
    });

    // Initial button state on page load
    const { isBeginning, isEnd } = this.swiper;

    $(".button-prev").attr("disabled", isBeginning);
    $(".button-next").attr("disabled", isEnd);
  }

  #attachLabelClickListener() {
    this.swiperWrapper.on("click", ".swiper-slide", (e) => {
      const label = e.target.dataset.value;
      const keywordParams = this.#getKeywordParams();

      // if label is already in search params, return
      if (keywordParams.includes(label)) return;

      keywordParams.push(label);
      this.#setKeywordParams(keywordParams);

      // update custom filters
      this.customFilterManager.updateKeywordsFilter();

      // dispatch event to fetch data
      eventTarget.dispatchEvent(new CustomEvent("fetch-data"));
    });
  }

  updateLabels(labels = {}) {
    this.labels = Object.keys(labels)
      .map((key) => ({ value: key, count: labels[key] }))
      .sort((a, b) => b.count - a.count);

    this.updateLabelSwiper();
  }

  updateLabelSwiper() {
    // delete existing labels / skeleton loader
    this.swiperWrapper.empty();

    const keywordParams = this.#getKeywordParams();

    // append inactive labels
    this.labels.forEach((label) => {
      if (!keywordParams.includes(label.value)) {
        this.swiperWrapper.append(
          `<div class="swiper-slide" data-value="${label.value}">${label.value} - ${label.count}</div>`
        );
      }
    });

    // update swiper
    this.swiper.update();
  }

  #getKeywordParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsString = searchParams.get(PARAM.KEYWORDS) || "[]";
    return JSON.parse(searchParamsString);
  }

  #setKeywordParams(keywordParams) {
    const searchParams = new URLSearchParams(window.location.search);
    const stringParams = JSON.stringify(keywordParams);

    const isKeywordsEmpty = keywordParams.length === 0;
    if (isKeywordsEmpty) {
      searchParams.delete(PARAM.KEYWORDS);
    } else {
      searchParams.set(PARAM.KEYWORDS, stringParams);
    }

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }
}

export default LabelSwiper;
