function defaultListener(e, param) {
  const value = e.target.checked;
  const searchParams = new URLSearchParams(window.location.search);
  if (value) {
    searchParams.set(param, value);
  } else {
    searchParams.delete(param);
  }

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${searchParams.toString()}`
  );
}

class Checkbox {
  constructor(checkBoxId, param, listenerCallback) {
    this.checkBox = $(`#${checkBoxId}`);
    this.param = param;
    this.listenerCallback = listenerCallback || defaultListener;
    this.#initValueFromParam();
    this.#appendListener();
  }

  reset() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(this.param);
    this.checkBox.prop("checked", false);

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  #initValueFromParam() {
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(this.param);
    if (value) {
      this.checkBox.prop("checked", true);
    }
  }

  #appendListener() {
    const param = this.param;
    this.checkBox.on("change", (e) => this.listenerCallback(e, param));
  }
}

export default Checkbox;
