class SelectWithDefaultValue {
  constructor(selectId, param, initialValue) {
    this.select = $(`#${selectId}`);
    this.param = param;
    this.initialValue = initialValue;
    this.#initDefaultParam();
    this.#appendListener();
    this.#initValueFromParam();
  }

  reset() {
    this.select.val(this.initialValue);
    this.select.selectmenu("refresh");

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(this.param, this.initialValue);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  #appendListener() {
    const param = this.param;
    this.select.selectmenu({
      width: "100%",
      change: function (event, ui) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(param, ui.item.value);

        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${searchParams.toString()}`
        );
      }
    })
  }

  #initValueFromParam(){
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(this.param);
    this.select.val(value);
    this.select.selectmenu("refresh");
  }

  #initDefaultParam(){
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(this.param);
    if (value === null) {
      searchParams.set(this.param, this.initialValue);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    }
  }
}

export default SelectWithDefaultValue;
