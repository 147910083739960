const PARAM = {
  COLLECTION: "collection",
  IMAGE_TYPES: "image_types",
  QUERY: "query",
  SORT: "sort",
  CREATORS: "creators",
  CONTRIBUTORS: "contributors",
  KEYWORDS: "keywords",
  DATE_TYPE: "date_type",
  ORIENTATION: "orientation",
  RECOMMENDED: "recommended",
  AERIAL: "aerial",
  FREE: "free",
  OLD10: "old10",
  TITLE: "title",
  DESCRIPTION: "description",
  DATE_FROM: "min",
  DATE_TO: "max",
};

export { PARAM };

