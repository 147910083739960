import { PARAM } from "../../constants/constants";
import { eventTarget } from "../../photoMain/PhotoMainModel";

class SortToggle {
  constructor() {
    this.#init();
    this.#appendListener();
  }

  reset() {
    // remove active class from children element of sort container
    $(".pbf-sort-container").children().removeClass("active");

    // add active class to the default sort value
    $(".pbf-sort-container").children().first().addClass("active");

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(PARAM.SORT);

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  #init() {
    // set active class to the default sort value
    const searchParams = new URLSearchParams(window.location.search);
    const sortValue = searchParams.get(PARAM.SORT) || "relevance";

    if (sortValue === "relevance") {
      return;
    }
    // remove class from all sort values
    $(".pbf-sort-container").children().removeClass("active");

    // add class to the active sort value
    const targetId = sortValue === "date desc" ? "date-desc" : "date-asc";
    $(`#${targetId}`).addClass("active");
  }

  #appendListener() {
    $(".pbf-sort-container").on("click", function (e) {
      // remove active class from children element of sort container
      $(e.target).siblings().removeClass("active");

      $(e.target).addClass("active");

      const sortValue = $(e.target).data("value");

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(PARAM.SORT, sortValue);

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );

      // dispatch event to fetch data
      eventTarget.dispatchEvent(new CustomEvent("fetch-data"));
    });
  }
}

export default SortToggle;
