import "../../../css/photobank-filter.css";
import { PARAM } from "../constants/constants";
import { eventTarget } from "../photoMain/PhotoMainModel";
import CustomFilterManager from "./customFilters/CustomFilterManager";
import DataChart from "./dataChart/DataChart";
import FetchResponseListener from "./FetchResponseListener";
import LabelSwiper from "./labelSwiper/LabelSwiper";
import ParamMapper from "./paramMapper/ParamMapper";
import FilterButton from "./searchbar/FilterButton";
import Searchbar from "./searchbar/Searchbar";
import SearchbarSelect from "./searchbar/SearchbarSelect";
import SortToggle from "./sortToggle/SortToggle";
import Checkbox from "./staticInputClasses/checkBox/Checkbox";
import old10Listener from "./staticInputClasses/checkBox/old10Listener";
import FromDateDatePicker from "./staticInputClasses/dateRangePicker/FromDateDatePicker";
import ToDateDatePicker from "./staticInputClasses/dateRangePicker/ToDateDatePicker";
import Select from "./staticInputClasses/select/Select";
import SelectWithDefaultValue from "./staticInputClasses/select/SelectWithDefaultValue";
import TextInput from "./staticInputClasses/textInputs/TextInput";
import Tooltip from "./tooltip/Tooltip";
import {
  keywordsHelperTooltipContent,
  searchbarHelperTooltipContent,
} from "./tooltip/tooltipContent";

const onResetFilter = (...args) => {
  $("#reset-filter-button").on("click", () => {
    args.forEach((param) => {
      param.reset();
    });
    eventTarget.dispatchEvent(new CustomEvent("fetch-data"));
  });
};

const onApplyFilter = () => {
  $("#apply-filter-button").on("click", () => {
    $(".pbf-collapse-wrapper").removeClass("open");
    eventTarget.dispatchEvent(new CustomEvent("fetch-data"));
  });
};

const PhotobankFilter = (function () {
  return {
    init: () => {
      new ParamMapper();
      // Init filter inputs, params and listeners
      // init dateRangePicker
      const fromDateDatePicker = new FromDateDatePicker();
      const toDateDatePicker = new ToDateDatePicker();

      // init select, checkbox and text inputs
      const dateTypeSelect = new SelectWithDefaultValue(
        "filter-date-type",
        PARAM.DATE_TYPE,
        "capture"
      );
      const orientationSelect = new Select(
        "filter-orientation",
        PARAM.ORIENTATION
      );
      const recommendedCheckbox = new Checkbox(
        "pbf-recommended",
        PARAM.RECOMMENDED
      );
      const aerialCheckbox = new Checkbox("pbf-aerial", PARAM.AERIAL);
      const freeCheckbox = new Checkbox("pbf-free", PARAM.FREE);
      const old10Checkbox = new Checkbox(
        "pbf-old10",
        PARAM.OLD10,
        old10Listener
      );
      const titleInput = new TextInput("title", PARAM.TITLE);
      const descriptionInput = new TextInput("description", PARAM.DESCRIPTION);
      const collectionSelect = new Select(
        "pbf-collection-type",
        PARAM.COLLECTION
      );
      const imageTypeSelect = new Select("pbf-image-types", PARAM.IMAGE_TYPES);

      // init custom filters
      const customFilterManager = new CustomFilterManager();

      // init searchbar
      const filterButton = new FilterButton();
      const searchbarSelect = new SearchbarSelect();
      const searchbarQueryInput = new Searchbar();
      new Tooltip("#pbf-search-info-tooltip", searchbarHelperTooltipContent);
      new Tooltip("#pbf-keywords-info-tooltip", keywordsHelperTooltipContent);
      const labelSwiper = new LabelSwiper(customFilterManager);
      customFilterManager.injectLabelSwiper(labelSwiper);
      const dataChart = new DataChart(fromDateDatePicker, toDateDatePicker);

      // init sort toggle
      const sortToggle = new SortToggle();

      new FetchResponseListener(
        fromDateDatePicker,
        toDateDatePicker,
        labelSwiper,
        filterButton,
        dataChart
      );

      onResetFilter(
        fromDateDatePicker,
        toDateDatePicker,
        dateTypeSelect,
        orientationSelect,
        recommendedCheckbox,
        aerialCheckbox,
        freeCheckbox,
        old10Checkbox,
        titleInput,
        descriptionInput,
        customFilterManager,
        searchbarSelect,
        searchbarQueryInput,
        collectionSelect,
        imageTypeSelect,
        sortToggle
      );
      onApplyFilter();
    },
  };
})();

export default PhotobankFilter;




