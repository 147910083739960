class CustomFilterContainer {

  static append(label, param) {
    this.#appendFilterRuleSelectContainer(param);
    this.#appendCardLabel(param, label);
    this.#appendRuleGroupContainer(param);
  }

  static #appendFilterRuleSelectContainer = (param) => {
    $(".pbf-filter-group-container").append(
      `<div id="${param}" class="custom-filter-container"></div>`
    );
  };

  static #appendCardLabel = (param, label) => {
    $(`#${param}`).append(`<h1 class="card-label">${label}</h1>`);
  };

  static #appendRuleGroupContainer = (param) => {
    $(`#${param}`).append(`<div id="${param}-input-container"></div>`);
  };
}

export default CustomFilterContainer;
