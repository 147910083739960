const searchbarHelperTooltipContent = function () {
  return `<div style="color: #333;">
                <h4>Keresési funkció</h4>
                <ul class="pbf-tooltip-list">
                  <li>Egy találat akkor jelenik meg, ha a keresési kifejezés valamely kiválasztott mezőben megtalálható.</li>
                  <li>Több szó is megadható, szóközzel elválasztva.</li>
                  <li>Pontos kifejezésre is kereshet, ha "idézőjelek" közé teszi.</li>
                  <li>Kombinálhatja a két módszert.</li>
                </ul>
              </div>`;
};

const keywordsHelperTooltipContent = function () {
  return `<div style="color: #333;">
                <h4>Kapcsolódó kulcsszavak</h4>
                <p>A kapcsolódó kulcsszvakkal tovább szűkítheti a keresést. A lista a megjelenített képek kulcsszavait  tartalmazza.</p>
                <p>Ha egy kulcsszóra kattint, az hozzáadódik a szűrőkhöz a filterben.</p>
              </div>`;
};

export { keywordsHelperTooltipContent, searchbarHelperTooltipContent };

