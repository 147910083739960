import { PARAM } from "../../constants/constants";
import { eventTarget } from "../../photoMain/PhotoMainModel";

class Searchbar {
  constructor() {
    this.param = PARAM.QUERY;
    this.#attachSearchQueryListener();
    this.#initSearchQuery();
  }

  reset() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(this.param);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );

    $("#pbf-query-search-input").val("");
  }

  #attachSearchQueryListener = () => {
    const self = this;
    $("#pbf-query-search-input").on("input", (e) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(self.param);
      searchParams.set(self.param, e.target.value);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    });

    // on pressing enter
    $("#pbf-query-search-input").on("keypress", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        eventTarget.dispatchEvent(new CustomEvent("fetch-data"));
      }
    });

    // on clicking search button
    $("#pbf-query-search-button").on("click", (e) => {
      e.preventDefault();
      eventTarget.dispatchEvent(new CustomEvent("fetch-data"));
    });
  };

  #initSearchQuery = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchQuery = searchParams.get(this.param) || "";
    $("#pbf-query-search-input").val(searchQuery);
  };
}

export default Searchbar;
