import Model from './PhotoMainModel';
import View from './PhotoMainView';
import PageController from '../../PageController'

class Controller extends PageController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;
    this._model.getPage()

    this._model.on('gotPage', result => {
      this._view.renderNumFound(result?.data?.response?.numFound ?? false)
      this._view.renderImages(result, true)
    })
  }
}

const PhotoItem = (function() {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    }
  }
})()

export default PhotoItem;
