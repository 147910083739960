import { DateTime } from "luxon";
import { PARAM } from "../../../constants/constants";

class FromDateDatePicker {
  constructor() {
    this.datePicker = $("#datepicker-start");
    this.datePickerPair = $("#datepicker-end");
    this.slider = $("#slider-range");
    this.#initDatepicker();
    this.#attachListener();
  }

  reset() {
    const [minYear, _] = $("#slider-range").data("defaultrange");

    const minDate = DateTime.fromISO(`${minYear}-01-01`).toJSDate();
    // destroy existing datepicker to avoid datepicker having funny behavior
    this.datePicker.datepicker("destroy");

    // reinitialize datepicker
    this.datePicker.datepicker({ dateFormat: "yy-mm-dd" });
    this.datePicker.datepicker("setDate", minDate);
    this.#setQueryParam(minDate);
  }

  setDateOnClickChartBar(date) {
    this.datePicker.datepicker("setDate", date);
  }

  #setQueryParam(date) {
    const searchParams = new URLSearchParams(window.location.search);
    const dateString = DateTime.fromJSDate(date).toISO().split("T")[0];
    searchParams.set("min", dateString);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  #initDatepicker() {
    this.datePicker.datepicker({ dateFormat: "yy-mm-dd" });

    const searchParams = new URLSearchParams(window.location.search);
    let currentMin = searchParams.get("min");

    if (!currentMin) {
      const [minYear, _] = this.slider
        .attr("data-defaultrange")
        .slice(1, -1)
        .split(",");
      currentMin = DateTime.fromISO(`${minYear}-01-01`).toJSDate();
    } else {
      currentMin = DateTime.fromISO(currentMin).toJSDate();
    }

    this.datePicker.datepicker("setDate", currentMin);
  }

  update(min, max) {
    const minDate = DateTime.fromISO(min).toJSDate();
    const maxDate = DateTime.fromISO(`${max}-12-31`).toJSDate();

    // set min and max date to avoid selecting invalid dates
    this.datePicker.datepicker("option", "minDate", minDate);
    this.datePicker.datepicker("option", "maxDate", maxDate);
  }

  #attachListener() {
    const self = this;
    this.datePicker.on("change", (event) => {
      const value = event.target.value;

      // when one date is changing from the pair, the other date should be updated
      // to avoid selecting invalid dates like from 2021-01-01 to 2020-01-01
      self.datePickerPair.datepicker(
        "option",
        "minDate",
        DateTime.fromISO(value).toJSDate()
      );

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(PARAM.DATE_FROM, value);

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${searchParams.toString()}`
      );
    });
  }
}

export default FromDateDatePicker;
