import { PARAM } from "../../constants/constants";

class ParamMapper {
  constructor() {
    this.#updateParams();
  }

  #updateParams() {
    const searchParams = new URLSearchParams(window.location.search);

    const creator = searchParams.get("creator");
    if (creator) {
      searchParams.delete("creator");
      const stringifiedCreators = JSON.stringify([creator]);

      searchParams.set(PARAM.CREATORS, stringifiedCreators);
    }

    const contributor = searchParams.get("contributor");
    if (contributor) {
      searchParams.delete("contributor");
      const stringifiedContributors = JSON.stringify([contributor]);

      searchParams.set(PARAM.CONTRIBUTORS, stringifiedContributors);
    }

    const keywords = searchParams.getAll("filters[]");
    if (keywords.length > 0) {
      searchParams.delete("filters[]");
      searchParams.set(PARAM.KEYWORDS, JSON.stringify(keywords));
    }

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }
}

export default ParamMapper;
