import { PARAM } from "../../constants/constants";
import { eventTarget } from "../../photoMain/PhotoMainModel";

class SearchbarSelect {
  constructor() {
    this.#toggleSelectListener();
    this.#selectOptionListener();
    this.#toggleClickOutsideListener();
    this.#initSelectValue();
  }

  reset() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(PARAM.IMAGE_TYPES);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );

    $("#pbf-search-select-type-value").text("Összes típus");
  }

  #toggleSelectListener() {
    $(".pbf-search-select").on("click", function (e) {
      $(".pbf-search-select-dropdown").toggleClass("open");
    });
  }

  #toggleClickOutsideListener() {
    $(document).on("click", function (e) {
      if (!$(e.target).closest(".pbf-search-select").length) {
        $(".pbf-search-select-dropdown").removeClass("open");
      }
    });
  }

  #selectOptionListener() {
    const self = this;
    $(document).on("click", ".pbf-search-select-dropdown li", function (e) {
      const selectedValue = $(this).data("value");
      $("#pbf-search-select-type-value").text(selectedValue);
      $(".pbf-search-select-dropdown").removeClass("open");
      self.#setQueryParams(e.target.id);

      eventTarget.dispatchEvent(new CustomEvent("fetch-data"));
    });
  }

  #setQueryParams = (selectedValue) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (selectedValue === "all") {
      searchParams.delete(PARAM.IMAGE_TYPES);
    } else {
      searchParams.set(PARAM.IMAGE_TYPES, selectedValue);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  };

  #initSelectValue = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedOption = searchParams.get(PARAM.IMAGE_TYPES) || "all";
    const selectedValue = $(`#${selectedOption}`).text();
    $("#pbf-search-select-type-value").text(selectedValue);
  };
}

export default SearchbarSelect;
