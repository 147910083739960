import { PARAM } from "../constants/constants";

const attachListeners = () => {
  $("#pbf-hero-search-form").on("submit", function (e) {
    e.preventDefault();

    const searchQuery = $("#pbf-hero-search").val();

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set(PARAM.QUERY, searchQuery);

    window.location.href = `${
      window.location.pathname
    }/search?${searchParams.toString()}`;
  });

  // get all the span items by class name tag-row-item go
  $(".tag-row-item.go").on("click", function () {
    const searchQuery = $(this).text();

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set(PARAM.QUERY, `"${searchQuery}"`);

    window.location.href = `${
      window.location.pathname
    }/search?${searchParams.toString()}`;
  });

  // filter button navigation
  $("#search_plus_btn").attr("href", `${window.location.pathname}/search`);

  // type dropdown
  $(".dropdown-menu.type-dropdown a").on("click", function (event) {
    event.preventDefault();

    // Get the selected value
    const selectedValue = $(this).data("tech");

    $("#tech_select").text($(this).text());

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set(PARAM.IMAGE_TYPES, selectedValue);

    window.location.href = `${
      window.location.pathname
    }/search?${searchParams.toString()}`;
  });

  // collection dropdown
  $(".dropdown-menu.category-dropdown a").on("click", function (event) {
    event.preventDefault();

    // Get the selected value
    const selectedValue = $(this).data("f");

    $("#cat_select").text($(this).text());

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set(PARAM.COLLECTION, selectedValue);

    window.location.href = `${
      window.location.pathname
    }/search?${searchParams.toString()}`;
  });
};

const PhotobankHeroSearch = (function () {
  return {
    init: () => {
      attachListeners();
    },
  };
})();

export default PhotobankHeroSearch;
