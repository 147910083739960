import { PARAM } from "../../constants/constants";

class FilterButton {
  constructor() {
    this.#filterButtonListener();
  }

  #filterButtonListener() {
    $("#pbf-filter-toggle").on("click", function (e) {
      e.preventDefault();

      $("#pbf-filter-toggle").toggleClass("open");
      $(".pbf-collapse-wrapper").toggleClass("open");
    });

    // click outside of the filter closes the filter
    $(document).on("click", function (e) {
      if (
        // avoid closing when clicking inside the filter
        $(e.target).closest("#photobank-filter-container").length ||
        // avoid closing when clicking on jquery selectmenu (select dropdown)
        $(e.target).closest(".ui-selectmenu-menu").length ||
        // avoid closing immediately after opening
        $(e.target).closest("#pbf-filter-toggle").length ||
        // avoid closing when interacting with jquery datepicker
        $(e.target).closest(".ui-datepicker").length ||
        $(e.target).closest(".ui-datepicker-header").length ||
        // avoid closing when interacting with select2 dropdowns
        $(e.target).closest(".select2-selection__choice__remove").length ||
        // avoid closing on removing free text input rule
        $(e.target).closest(".input-button").length
      ) {
        return;
      }

      $(".pbf-collapse-wrapper").removeClass("open");
      $("#pbf-filter-toggle").removeClass("open");
    });
  }

  updateCounter() {
    let ruleCount = 0;

    const fromDate = $("#datepicker-start").datepicker("getDate");
    const fromMin = $("#datepicker-start").datepicker("option", "minDate");

    if (fromMin && fromDate.getTime() !== fromMin?.getTime()) {
      ruleCount++;
    }

    const toDate = $("#datepicker-end").datepicker("getDate");
    const toMax = $("#datepicker-end").datepicker("option", "maxDate");

    if (toMax && toDate.getTime() !== toMax?.getTime()) {
      ruleCount++;
    }

    const searchParams = new URLSearchParams(window.location.search);

    // check if date type is set other than default
    const dateType = searchParams.get(PARAM.DATE_TYPE);
    if (dateType !== "capture") {
      ruleCount++;
    }

    const orientation = searchParams.get(PARAM.ORIENTATION);
    if (orientation) {
      ruleCount++;
    }

    const recommended = searchParams.get(PARAM.RECOMMENDED);
    if (recommended) {
      ruleCount++;
    }

    const aerial = searchParams.get(PARAM.AERIAL);
    if (aerial) {
      ruleCount++;
    }

    const free = searchParams.get(PARAM.FREE);
    if (free) {
      ruleCount++;
    }

    const old10 = searchParams.get(PARAM.OLD10);
    if (old10) {
      ruleCount++;
    }

    const title = searchParams.get(PARAM.TITLE);
    if (title) {
      ruleCount++;
    }

    const description = searchParams.get(PARAM.DESCRIPTION);
    if (description) {
      ruleCount++;
    }

    const collection = searchParams.get(PARAM.COLLECTION);
    if (collection) {
      ruleCount++;
    }

    const imageTypes = searchParams.get(PARAM.IMAGE_TYPES);
    if (imageTypes) {
      ruleCount++;
    }

    // contributor, keywords, creator

    const contributor = searchParams.get(PARAM.CONTRIBUTORS);

    if (contributor) {
      const parsedContributor = JSON.parse(contributor);
      ruleCount += parsedContributor.length;
    }

    const keywords = searchParams.get(PARAM.KEYWORDS);
    if (keywords) {
      const parsedKeywords = JSON.parse(keywords);
      ruleCount += parsedKeywords.length;
    }

    const creator = searchParams.get(PARAM.CREATORS);
    if (creator) {
      const parsedCreator = JSON.parse(creator);
      ruleCount += parsedCreator.length;
    }

    $("#pbf-filter-toggle").append(
      `<span class="filter-rule-counter">${ruleCount}</span>`
    );
  }
}

export default FilterButton;
